import { Grommet, Box, Image, Carousel, CardHeader, Grid, CardBody, Heading, Page, PageContent } from 'grommet';

import { useState, useEffect } from 'react';

function useWindowDimensions() {

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}

const theme = {
  global: {
    colors: {
      brand: 'white',
    },
    font: {
      family: 'Rock Salt',
      size: '16px',
      height: '20px',
    },
  },
};

const data = [
  'j1.png',
  'j2.png',
  'j3.png',
  'j4.png',
  'j5.png',
  'j6.png',
  'j7.jpg',
  'j8.jpg',
  'j9.jpg',
];

export const Multi = () => {
  const {height, width} = useWindowDimensions();
  let items = 1;
  if (width > 1024) {
    items = 3;
  } else if (width > 600) {
    items = 2;
  }

  let res = [];

  for (let i = 0; (i + items) <= data.length; i+=items) {

    const imgs = data.slice(i, i + items);
    res.push(
      <Box direction="row">
        {imgs.map((img) => (
          <Image key={img} src={img} fit="cover" />
        ))}
      </Box>
    )
  }

  // Uncomment <Grommet> lines when using outside of storybook
  // <Grommet theme={...}>
  return (<Box>
    <Carousel controls={true} play={5000}>
      {res}
    </Carousel>
  </Box>)
  // </Grommet>
}

const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='xsmall'
    style={{ zIndex: '1' }}
    {...props}
  />
);

const Experience = (props) => (
  <Box pad="medium" direction={"row-responsive"} justify={"evenly"}>
    <Box pad={{vertical:"medium"}}>
      <CardHeader pad="small" justify="center"><u><b>PROJECT MANAGEMENT</b></u></CardHeader>
      <Box align={"center"} pad={"small"}>
        <Box pad={"xxsmall"}>- Account Management</Box>
        <Box pad={"xxsmall"}>- Timeline Tracking</Box>
        <Box pad={"xxsmall"}>- Cross Department Delegation</Box>
      </Box>
    </Box>
    <Box pad={{vertical:"medium"}}>
      <CardHeader pad="small" justify="center"><u><b>PUBLIC RELATIONS</b></u></CardHeader>
      <Box align={"center"} pad={"small"}>
        <Box pad={"xxsmall"}>- Event Coordination</Box>
        <Box pad={"xxsmall"}>- Talent Relations</Box>
        <Box pad={"xxsmall"}>- Media Preparation</Box>
      </Box>
    </Box>
    <Box pad={{vertical:"medium"}}>
      <CardHeader pad="small" justify="center"><u><b>MARKETING</b></u></CardHeader>
      <Box align={"center"} pad={"small"}>
        <Box pad={"xxsmall"}>- Brand Partnerships </Box>
        <Box pad={"xxsmall"}>- Program Development </Box>
        <Box pad={"xxsmall"}>- Campaign Strategy</Box>
      </Box>
    </Box>
  </Box>
)

function App() {
  const [image, setImage] = useState(0)
  const { height, width } = useWindowDimensions();
  let brands = [
    'respin2.png',
    //'halo.png',
    'shopify.png',
    'halo2.png',
    'target.png',
    'blush.png',
    //'blush2.png',
    'cantu.png',
    'shea.png',
    'beautyunited.png',
    's.png',
    'casper.png',
    'breville.png',
    'sweaty.png',
    'flow.png',
    'revelations.png',
  ]
  if (width > 431 ) {
    brands.push('abc.png');
  }
  setTimeout(() => {
    setImage(image + 1);
  }, 3000);

  const display = ["heroblue.jpg", "hb_smaller.jpg","anotherhero.jpg"][image%3];

  return (
    <Grommet theme={theme}>
      <AppBar><Heading level='3' margin='none' style={{fontFamily: 'The Nautigal', fontSize:"64px", lineHeight:"1.125"}}>Jenny Sok</Heading></AppBar>
      <Box height={(width > 1200 ? 500 : 400) + "px"} border>
        <Image src={display} fit="cover" />
      </Box>
      <Experience />
      <Page kind="narrow">
        <PageContent>
          <Grid columns="xsmall" gap="large">
            {brands.map((image) => (
              <Box
                key={image}
                align="center"
                justify={"center"}
                pad={image ==="target.png" ? "medium" : ( image ==="abc.png" ? "small" : "")}
              >
                <img src={image} width="100%"/>
              </Box>
            ))}
          </Grid>
        </PageContent>
      </Page>
      <Multi/>
          <Box>
            <iframe
              src="https://docs.google.com/gview?a=v&pid=explorer&chrome=false&api=true&embedded=true&srcid=1_6QdBTHTPD0lI1iPTecrCBsNa0IUlYWl&hl=en&embedded=true"
              frameBorder="0" height={width < 800 ? (1060 - (800 - width/(width < 500 ? (width < 450 ? 1.4 : 1.3) : (width < 600 ? 1.17 : 1.04)))) : 1080} marginHeight="0" marginWidth="0" allowFullScreen="true"
              mozallowfullscreen="true" webkitallowfullscreen="true"/>
          </Box>
    </Grommet>
  );
}
export default App;
